import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
    Box, Grid, Card, Button, Typography, Stack,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Checkbox,
    TablePagination,
    useTheme
} from '@mui/material';

//
import AccountBillingAddressBook from './AccountBillingAddressBook';
import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import { _userList } from '../../../../_mock';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../user/list';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import PaymentListToolbar from 'src/sections/@dashboard/general/payments/paymentListToolbar';
import '../../../../styles/general.css';
import PaymentTable from 'src/components/PaymentTable';
import { get_payments } from 'src/api/API';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

AccountTransactions.propTypes = {
    addressBook: PropTypes.array,
    cards: PropTypes.array,
    invoices: PropTypes.array,
};


export default function AccountTransactions({ websiteId, cards, addressBook, invoices }) {
    const [open, setOpen] = useState(false);
    const { allLang, currentLang, translate, onChangeLang } = useLocales();
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState("all");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [userList, setUserList] = useState(_userList);
    const [filterName, setFilterName] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [allPayments, setAllPayments] = useState([]);
    const [ongoingPayments, setOngoingPayments] = useState([]);
    const [successedPayments, setSuccessedPayments] = useState([]);
    const [failedPayments, setFailedPayments] = useState([]);



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

    const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && Boolean(filterName);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (checked) => {
        if (checked) {
            const newSelecteds = userList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    useEffect(() => {
        get_data(0);

    }, []);

    const get_data = async (status) => {
        setIsLoading(true);
        var data = await get_payments({
            payment_status_id: status,
            website_id: websiteId
        });

        if (data.status) {
            console.log(data.data);
            if (status == 0) {
                setAllPayments(data.data);
            } else if (status == 2) {

                setOngoingPayments(data.data);
            } else if (status == 3) {
                setSuccessedPayments(data.data);
            } else if (status == 4) {
                setFailedPayments(data.data);
            }
            setIsLoading(false);
        }
    }

    const changeTab = (tab) => {
        setActiveTab(tab);
        get_data(STATUS_ID[tab]);
    }

    const handleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteUser = (userId) => {
        const deleteUser = userList.filter((user) => user.id !== userId);
        setSelected([]);
        setUserList(deleteUser);
    };

    const handleDeleteMultiUser = (selected) => {
        const deleteUsers = userList.filter((user) => !selected.includes(user.name));
        setSelected([]);
        setUserList(deleteUsers);
    };

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const sampleData = [
        {
            date: "2022/01/01 22:01:20",
            email: "John@gmail.com",
            name: "John Doe",
            payment_id: "p_j39adfm39ad34cm94h",
            bank: "Maybank",
            status: 1
        }
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const STATUS_ID = {
        all: 0,
        ongoing: 2,
        successed: 3,
        failed: 4
    }

    return (
        <Grid container spacing={5}>

            <Grid item xs={12} md={12}>
                <Stack spacing={3}>

                    <div className="pill_nav">
                        <div
                            onClick={() => {
                                changeTab("all");
                            }}
                            className={activeTab == "all" ? "_item active" : "_item"}>{translate("All")}</div>
                        <div
                            onClick={() => {
                                changeTab("ongoing");
                            }}
                            className={activeTab == "ongoing" ? "_item active" : "_item"}>{translate("Ongoing")}</div>
                        <div
                            onClick={() => {
                                changeTab("successed");
                            }}
                            className={activeTab == "successed" ? "_item active" : "_item"}>{translate("Successed")}</div>
                        <div
                            onClick={() => {
                                changeTab("failed");
                            }}
                            className={activeTab == "failed" ? "_item active" : "_item"}>{translate("Failed")}</div>

                    </div>

                    {activeTab == 'all' && !isLoading && (<PaymentTable data={allPayments} />)}
                    {activeTab == 'ongoing' && !isLoading && (<PaymentTable data={ongoingPayments} />)}
                    {activeTab == 'successed' && !isLoading && (<PaymentTable data={successedPayments} />)}
                    {activeTab == 'failed' && !isLoading && (<PaymentTable data={failedPayments} />)}


                </Stack>
            </Grid>
        </Grid>
    );
}
