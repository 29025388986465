import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, TextField, MenuItem, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { get_website_user } from 'src/api/API';
import { useEffect, useState } from 'react';
import { set } from 'lodash';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

export default function AccountUsers(props) {
    const { enqueueSnackbar } = useSnackbar();

    const { allLang, currentLang, translate, onChangeLang } = useLocales();
    const ChangePassWordSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Old Password is required'),
        newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const [users, setUsers] = useState([]);
    useEffect(()=>{
        get_users();
    },[]);
    const get_users = async () => {
        var response = await get_website_user({
            website_id: props.websiteId
        });

        if (response.status) {
            setUsers(response.data);
        }
    };

    const defaultValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const methods = useForm({
        resolver: yupResolver(ChangePassWordSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
            enqueueSnackbar('Update success!');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Card sx={{ p: 3 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Last Active</TableCell>
                            <TableCell>Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(row => (
                            <TableRow>
                                <TableCell>{row.username}</TableCell>
                                <TableCell>{row.role}</TableCell>
                                <TableCell>{row.last_active}</TableCell>
                                <TableCell>{row.created_date}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </FormProvider>
        </Card>
    );
}
