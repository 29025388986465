import React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
import { countries } from '../../../../_mock';
// components
import { FormProvider, RHFSwitch, RHFSelect, RHFTextField, RHFUploadAvatar } from '../../../../components/hook-form';
import toast from 'react-simple-toasts';
import { update_website_transfer_info } from '../../../../api/API';
import useLocales from 'src/hooks/useLocales';
import { Translate } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function AccountGeneral(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const [websiteData, setWebsiteData] = React.useState({
    name: props.data.name,
    website_url: props.data.website_url,
    callback_url: props.data.callback_url,
    country_id: props.data.country_id,
    currency_id: props.data.currency_id,
  });
  const [transactionData, setTransactionData] = React.useState({
    withdrawal_bank: props.data.withdrawal_bank,
    withdrawal_bank_account_number: props.data.withdrawal_bank_account_number,
    withdrawal_bank_account_name: props.data.withdrawal_bank_account_name,
    credit_limit: props.data.credit_limit,
    deposit_bank: props.data.deposit_bank,
    deposit_bank_account_name: props.data.deposit_bank_account_name,
    deposit_bank_account_number: props.data.deposit_bank_account_number,
    withdrawal_payment_method_type_id: props.data.withdrawal_payment_method_type_id,
    withdrawal_USDT_address: props.data.withdrawal_USDT_address
  });
  useEffect(() => {
    console.log(props);
  }, []);

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });

  const defaultValues = {
    displayName: user?.displayName || '',
    email: user?.email || '',
    photoURL: user?.photoURL || '',
    phoneNumber: user?.phoneNumber || '',
    country: user?.country || '',
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
    zipCode: user?.zipCode || '',
    about: user?.about || '',
    isPublic: user?.isPublic || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitTransaction = async () => {
    var response = await update_website_transfer_info({
      ...transactionData,
      website_id: props.websiteId
    });
    if (response.status) {
      toast("Updated");
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleChangeTransaction = (e) => {
    setTransactionData({
      ...transactionData,
      [e.target.name]: e.target.value
    });
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Typography variant="subtitle1">{translate("General")}</Typography>
            <br />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="name" value={props.data.name} label={translate("Name")} />
              <RHFTextField name="website" label={translate("Website")} value={props.data.website_url} />
              <TextField name="country" label={translate("Country")} value={props.data.country}>
              </TextField>
              <TextField name="currency" label={translate("Currency")} value={props.data.currency}></TextField>

            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

              {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton> */}
            </Stack>
          </Card>
        </Grid>
        {process.env.REACT_APP_USER_TYPE == 'admin' && (
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Typography variant="subtitle1">{translate("Deposit")}</Typography>
              <br />
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <TextField name="deposit_bank" label={translate("Bank")}
                  onChange={handleChangeTransaction}
                  value={transactionData.deposit_bank} />
                <TextField
                  onChange={handleChangeTransaction}
                  name="deposit_bank_account_name" label={translate("Acc. Name")} value={transactionData.deposit_bank_account_name} />
                <TextField
                  onChange={handleChangeTransaction}
                  name="deposit_bank_account_number" label={translate("Acc. No")} value={transactionData.deposit_bank_account_number} />

                <hr />
                <Typography variant="subtitle1">{translate("Withdrawal")}</Typography>


                <TextField
                  onChange={handleChangeTransaction}
                  name="credit_limit" label="Credit Limit" value={transactionData.credit_limit}></TextField>
                <TextField select onChange={handleChangeTransaction} label={translate("Channel")} name="withdrawal_payment_method_type_id" value={transactionData.withdrawal_payment_method_type_id}>
                  <MenuItem value={1}>{translate("Bank")}</MenuItem>
                  <MenuItem value={2}>USDT</MenuItem>
                </TextField>
                {transactionData.withdrawal_payment_method_type_id == 2 ? (
                  <TextField onChange={handleChangeTransaction} label={translate("Address")} name="withdrawal_USDT_address" value={transactionData.withdrawal_USDT_address} />
                ) : (
                  <>
                    <TextField
                      onChange={handleChangeTransaction}
                      name="withdrawal_bank" label={translate("Bank")} value={transactionData.withdrawal_bank} />
                    <TextField
                      onChange={handleChangeTransaction}
                      name="withdrawal_bank_account_name" label={translate("Acc. Name")} value={transactionData.withdrawal_bank_account_name} />
                    <TextField
                      onChange={handleChangeTransaction}
                      name="withdrawal_bank_account_number" label={translate("Acc. No")} value={transactionData.withdrawal_bank_account_number} />

                  </>
                )}






              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

                <LoadingButton type="button" variant="contained"
                  onClick={handleSubmitTransaction}
                >
                  {translate("Save Changes")}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        )}

      </Grid>
    </FormProvider>
  );
}
