import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import {
    Modal, Stack,
    Switch, FormGroup, FormControlLabel,
    Card, Grid, Box, Typography, Radio, CardHeader, Button, List, ListItemButton, Divider, ListItem, IconButton, Table, TableHead, TableRow, TableCell, TableBody, TextField, Dialog, FormControl, FormLabel, RadioGroup, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { addIcon } from '@iconify/react';
import SvgIconStyle from 'src/components/SvgIconStyle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { get_credentials, update_website_withdrawal_rates, get_website_payment_methods, update_website_payment_methods, get_available_methods_for_website, add_payment_method_website, delete_website_payment_method, get_country_payment_method } from 'src/api/API';
import toast from 'react-simple-toasts';
import useLocales from 'src/hooks/useLocales';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export default function AccountIntegrations(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { allLang, currentLang, translate, onChangeLang } = useLocales();
    const [countryPayments, setCountryPayments] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [openAddPaymentMethodsModal, setOpenAddPaymentMethodsModal] = React.useState(false);
    const [availableMethods, setAvailableMethods] = React.useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(0);
    const [withdrawalForm, setWithdrawalForm] = React.useState({
        withdrawal_rate: props.data.withdrawal_rate,
        withdrawal_limit: props.data.withdrawal_limit,
        withdrawal_bank_fees: props.data.withdrawal_bank_fees,
        withdrawal_fees_on: props.data.withdrawal_fees_on,
        withdrawal_bank_fees_on: props.data.withdrawal_bank_fees_on
    });

    const getPaymentMethods = async () => {
        var response = await get_country_payment_method({
            country_id: props.data.country_id,
        });

        if (response.status) {
            setCountryPayments(response.data);
        }
    }

    const get_methods = async () => {
        var response = await get_available_methods_for_website({
            website_id: props.websiteId
        });

        if (response.status) {
            setAvailableMethods(response.data);
        }
    };

    const handleSelectAvailableMethod = (id) => {
        setSelectedPaymentMethod(id);
    }

    const handleChangeWithdrawal = (e) => {
        setWithdrawalForm({
            ...withdrawalForm,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmitWithdrawal = async () => {
        var response = await update_website_withdrawal_rates({
            ...withdrawalForm,
            website_id: props.websiteId
        });
        if (response.status) {
            toast("Updated");
            props.refresh();
        }

    };

    useEffect(() => {
        get_data();
        get_methods();
        getPaymentMethods();
    }, []);
    const get_data = async () => {
        var response = await get_website_payment_methods({
            website_id: props.websiteId
        });
        if (response.status) {

            setPaymentMethods(response.data);
        }
    };

    const defaultValues = {
        callback_url: props.data.callback_url,
        return_url: props.data.return_url,

    };

    const handleAddPaymentMethod = async () => {
        var response = await add_payment_method_website({
            payment_method_id: selectedPaymentMethod,
            website_id: props.websiteId
        });

        if (response.status) {
            handleCloseAddPaymentMethodModal();
            get_data();
            props.refresh();
            toast("Added");
        }
    }

    const handleCloseAddPaymentMethodModal = () => {
        setOpenAddPaymentMethodsModal(false);
        setSelectedPaymentMethod(0);
    }
    const handleChange = (e, p_method) => {
        p_method = {
            ...p_method,
            [e.target.name]: e.target.value
        };

        var t_payment_methods = [...paymentMethods];
        for (let i = 0; i < t_payment_methods.length; i++) {
            if (t_payment_methods[i].payment_method_website_id != p_method.payment_method_website_id) {
                continue;
            }
            t_payment_methods[i] = p_method;
        }
        setPaymentMethods(t_payment_methods);
    }

    const handleSubmit = async (p_method) => {
        var response = await update_website_payment_methods({
            ...p_method,
            website_id: props.websiteId
        })
        if (response.status) {
            props.refresh();
            toast("Updated");
        }
    }

    const deleteWebsitePaymentMethod = async (payment_method_website_id) => {
        var response = await delete_website_payment_method({
            payment_method_website_id: payment_method_website_id
        });

        if (response.status) {
            get_data();
            props.refresh();
            toast("Updated");
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <Typography variant="subtitle1">{translate("Withdrawal")}</Typography>

                            <>
                                {process.env.REACT_APP_USER_TYPE == 'admin' && (
                                    <>
                                        <TextField
                                            onChange={handleChangeWithdrawal}
                                            name="withdrawal_rate" value={withdrawalForm.withdrawal_rate} label={translate("Rate")} step="0.01" />
                                        <TextField
                                            onChange={handleChangeWithdrawal}
                                            name="withdrawal_bank_fees" value={withdrawalForm.withdrawal_bank_fees} label={translate("Bank Fees")} step="0.01" />
                                        <FormControl>
                                            <FormLabel>{translate("Bank Fees Charge To")}</FormLabel>
                                            <RadioGroup
                                                name="withdrawal_bank_fees_on"
                                                value={withdrawalForm.withdrawal_bank_fees_on}
                                                onChange={handleChangeWithdrawal}
                                                row>
                                                <FormControlLabel value="CLIENT" control={<Radio />} label={translate("Client")} />
                                                <FormControlLabel value="PLATFORM" control={<Radio />} label={translate("Platform")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>{translate("Withdrawal Fees Charge To")}</FormLabel>
                                            <RadioGroup row
                                                name="withdrawal_fees_on"
                                                value={withdrawalForm.withdrawal_fees_on}
                                                onChange={handleChangeWithdrawal}
                                            >
                                                <FormControlLabel value="CLIENT" control={<Radio />} label={translate("Client")} />
                                                <FormControlLabel value="USER" control={<Radio />} label={translate("Customer")} />
                                            </RadioGroup>
                                        </FormControl>

                                    </>
                                )}

                                {/* <TextField
                                    onChange={handleChangeWithdrawal}
                                    name="withdrawal_limit" value={withdrawalForm.withdrawal_rate}
                                    value={withdrawalForm.withdrawal_limit}
                                    helperText={"Withdrawals above this amount will require verification before being processed"}
                                    label="Withdrawal Guard" step="0.01" /> */}
                            </>
                            <LoadingButton type="button" variant="contained" onClick={() => {
                                handleSubmitWithdrawal();
                            }}>
                                {translate("Save Changes")}
                            </LoadingButton>
                        </Stack>

                    </Card>
                </Grid>
                {process.env.REACT_APP_USER_TYPE == 'admin' && (
                    <Grid item md={6}>
                        <div style={{
                            display: "flex",
                            paddingTop: 8,
                            paddingBottom: 8,
                            alignItems: "flex-end",
                            justifyContent: "flex-end"

                        }}
                        >
                            <Button
                                onClick={() => {
                                    setOpenAddPaymentMethodsModal(true);
                                }}
                                variant='contained'
                            >{translate("Add")}</Button>
                        </div>
                        {
                            paymentMethods.map(row => (
                                <Card sx={{ p: 3 }} style={{
                                    marginBottom: 10
                                }}>
                                    <CardHeader
                                        action={<Button variant="contained" onClick={() => { deleteWebsitePaymentMethod(row.payment_method_website_id) }}>{translate("delete")}</Button>}
                                    ></CardHeader>
                                    <Stack spacing={3}>
                                        <Typography variant="subtitle1">{row.channel}</Typography>
                                        <TextField name="rate"
                                            onChange={(e) => handleChange(e, row)}
                                            value={row.rate} label={translate("Rate")} ></TextField>
                                        {row.payment_method_type_id == 1 &&
                                            <>
                                                <TextField name="country_payment_method_id" value={row.country_payment_method_id} label={translate("account")} select onChange={(e) => handleChange(e, row)}>
                                                    {countryPayments.map(r => {
                                                        if (r.payment_method_type_id == 2) { return }
                                                        return (
                                                            <MenuItem value={r.country_payment_method_id}>
                                                                {r.name} - {r.bank}({r.bank_account_number})
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>
                                            </>}

                                        {row.payment_method_type_id == 2 &&
                                            <>
                                                <TextField name="country_payment_method_id" value={row.country_payment_method_id} label={translate("account")} select onChange={(e) => handleChange(e, row)}>
                                                    {countryPayments.map(r => {
                                                        if (r.payment_method_type_id == 1) { return }
                                                        return (
                                                            <MenuItem value={r.country_payment_method_id}>
                                                                {r.name} - {r.bank}({r.USDT_address})
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>
                                            </>}

                                        <LoadingButton type="button" variant="contained" onClick={() => {
                                            handleSubmit(row);
                                        }}>
                                            {translate("Save Changes")}
                                        </LoadingButton>
                                    </Stack>

                                </Card>
                            ))
                        }
                    </Grid>
                )}

            </Grid>

            <br />

            <Modal open={openAddPaymentMethodsModal}
                onClose={handleCloseAddPaymentMethodModal}
            >
                <div className='modal_content'>
                    <Typography variant="subtitle1">Add New Payment Method</Typography>
                    <div className="payment_methods">
                        {
                            availableMethods.map((row) => (
                                <div className={selectedPaymentMethod == row.payment_method_type_id ? '_item active' : "_item"} onClick={() => {
                                    handleSelectAvailableMethod(row.payment_method_type_id);
                                }}>
                                    <Typography variant="subtitle2">{row.payment_method_type}</Typography>
                                </div>
                            ))
                        }
                    </div>
                    {
                        selectedPaymentMethod != 0 && (
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end"
                            }}>
                                <Button
                                    onClick={() => {
                                        handleAddPaymentMethod()
                                    }}
                                    variant='contained'>Add</Button>
                            </div>
                        )
                    }

                </div>
            </Modal>

        </>
    );
}
