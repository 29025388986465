import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
    Card,
    Table,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    IconButton,
    Modal,
    TextField,
    Grid,
    MenuItem,
    Chip,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useSettings from '../hooks/useSettings';

// components
import Page from './Page';
import Label from './Label';
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';
import SearchNotFound from './SearchNotFound';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user/list';
import '../styles/general.css';
import PaymentListToolbar from 'src/sections/@dashboard/general/payments/paymentListToolbar';
import PaymentListHead from 'src/sections/payment/PaymentListHead';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Check from '@mui/icons-material/Check';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { amend_payment, get_rejection_reason, set_payment_to_process, unset_payment_to_process, update_payment_status } from 'src/api/API';
import { Box } from '@mui/system';
import toast from 'react-simple-toasts';
import ImageIcon from '@mui/icons-material/Image';
import useLocales from 'src/hooks/useLocales';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from '../hooks/useAuth';



const status_string = [
    "",
    "Ongoing",
    "Pending Approval",
    "Successed",
    "Failed"
];
const PaymentTable = (props) => {
    const theme = useTheme();
    const { themeStretch } = useSettings();

    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [focusedPayment, setFocusedPayment] = useState(0);

    const [rejectionReasons, setRejectionReasons] = useState([]);

    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [paymentData, setPaymentData] = useState([]);
    const { allLang, currentLang, translate, onChangeLang } = useLocales();
    const [tableHead, setTableHead] = useState([]);
    const { user } = useAuth();
    var TABLE_HEAD_1 = [
        { id: "date", label: translate("Date"), alignRight: false },
        { id: "payment_id", label: translate("Payment ID"), alignRight: false },

    ];
    var TABLE_HEAD_2 = [
        { id: "amount", label: translate("Amount"), alignRight: true },
        { id: "Channel", label: translate("Bank"), alignRight: false },
        { id: 'status', label: translate("Status"), alignRight: false },
        { id: 'action', label: "" }
    ];
    if (process.env.REACT_APP_USER_TYPE == 'admin') {
        var TABLE_HEAD = [
            { id: "check", label: "" },
        ];
    } else {
        var TABLE_HEAD = [];
    }

    const [advancedSearch, setAdvancedSearch] = useState({
        date_from: '',
        date_to: '',
        time_from: '',
        time_to: '',
        bank: '',
        status: ''
    });
    const [rejectionForm, setRejectionForm] = useState({
        reason: 1,
        remarks: ""
    });

    const [openImage, setOpenImage] = useState(false);
    const [focusedImage, setFocusedImage] = useState('');
    const [rowsChecked, setRowsChecked] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [openEditPaymentModal, setOpenEditPaymentModal] = useState(false);
    const [amendPaymentReason, setAmendPaymentReason] = useState('');

    const handleAmendPayment = async (status_id) => {
        if (handleAmendPayment == '') {
            alert("Please provide a reason");
            return;
        }
        var response = await amend_payment({
            payment_id: focusedEditPayemnt.payment_id,
            status_id: status_id
        });
        if (response.status) {
            setOpenEditPaymentModal(false);
            toast("Updated");
            props.refresh();
        }
    }

    useEffect(() => {


        if (props.data.result) {
            var t_payment_data = [...paymentData];
            var t_data = [...props.data.result];

            for (var i = 0; i < t_data.length; i++) {
                t_data[i]['checked'] = false;
            }
            if (t_payment_data.length > 0) {
                for (var i = 0; i < t_payment_data.length; i++) {
                    if (!t_payment_data[i].checked) {
                        continue;
                    }
                    for (var j = 0; j < t_data.length; j++) {
                        if (t_payment_data[i].payment_id == t_data[j].payment_id) {
                            t_data[j].checked = true;
                        }
                    }
                }
            }
            setPaymentData(t_data);
        }

        TABLE_HEAD_1.map(r => TABLE_HEAD.push(r));
        if (props.data.fields) {
            for (var i = 0; i < props.data.fields.length; i++) {
                TABLE_HEAD.push({ id: props.data.fields[i].name, label: translate(props.data.fields[i].name), alignRight: false },)
            }

        }
        TABLE_HEAD_2.map(r => TABLE_HEAD.push(r));
        setTableHead(TABLE_HEAD);
        setLoaded(true);

        get_reasons();
    }, [props.data]);

    const get_reasons = async () => {
        var response = await get_rejection_reason();
        if (response.status) {
            setRejectionReasons(response.data);
        }
    }

    const [focusedEditPayemnt, setFocusedEditPayment] = useState(null);
    const handleEditPayment = (row) => {
        setOpenEditPaymentModal(true);
        setFocusedEditPayment(row);
    };
    const handleOpenImage = (img) => {

        setFocusedImage(img);
        setOpenImage(true);
    }
    const handleCloseImage = (img) => {
        setFocusedImage('');
        setOpenImage(false);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleCheck = (e, row) => {
        var t_data = [...paymentData];
        row.checked = !row.checked;

        if (e.target.checked) {
            setRowsChecked(rowsChecked + 1);
        } else {
            setRowsChecked(rowsChecked - 1);
        }
        setPaymentData(t_data);
    }

    const handleSelectAllClick = (checked) => {
        if (checked) {

            return;
        }
        setSelected([]);
    };

    const handleClick = (name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const update_status = async (payment_id, status) => {
        var temp_data = [...paymentData];

        var post_data = {
            payment_id: payment_id,
            status: status
        };
        if (status == 4) {
            if (rejectionForm.reason == '' || rejectionForm.reason == 0) {

            }
            post_data = {
                ...post_data,
                ...rejectionForm
            }
        }
        var response = await update_payment_status(post_data)

        if (response.status) {
            for (var i = 0; i < temp_data.length; i++) {
                if (payment_id != temp_data[i]['payment_id']) {
                    continue;
                }
                temp_data[i]['payment_status_id'] = status;
                temp_data[i]['status_string'] = status_string[status];
                setPaymentData(temp_data);
                return;
            }
            setOpenModal(false);
        }
    };

    const handleRejectionFormChange = (e) => {
        setRejectionForm({
            ...rejectionForm,
            [e.target.name]: e.target.value
        });
    };

    const setToProcess = async () => {
        var rowsToSend = [];

        for (var i = 0; i < paymentData.length; i++) {
            if (paymentData[i].checked) {
                rowsToSend.push(paymentData[i].payment_id);
            }
        }
        var data = {
            rows: JSON.stringify(rowsToSend)
        };
        var response = await set_payment_to_process(data);

        if (response.status) {
            toast("Updated");
            props.refresh();
        }

    };

    const handleUnassign = async (payment_id) => {
        var response = await unset_payment_to_process({
            payment_id: payment_id
        });

        if (response.status) {
            toast("Updated");
            props.refresh();
        }
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentData.length) : 0;

    const filteredUsers = applySortFilter(paymentData, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && Boolean(filterName);

    const openPaymentDetails = (payment_id) => {

        window.open(window.location.origin + "/dashboard/payments/details/" + payment_id, "_blank");
    };

    const status_colors = ["info", "info", "info", "primary", "error"];
    return (
        <>
            {loaded == true && (
                <Card>

                    <div style={{
                        display: "flex",
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 10
                    }}>

                        <TextField
                            style={{
                                marginRight: 10
                            }}
                            margin="dense"
                            placeholder={translate("search payment ID")}
                        ></TextField>

                        <div style={{
                            display: "flex",

                        }}>
                            <Button
                                style={{
                                    marginTop: 8,
                                    marginBottom: 4

                                }}

                                variant="contained">{translate("Search")}</Button>
                        </div>
                        <div style={{
                            marginLeft: "auto",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            {rowsChecked > 0 && (
                                <Button variant="contained"
                                    onClick={setToProcess}
                                >{translate("process")}</Button>
                            )}

                        </div>
                    </div>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <PaymentListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={tableHead}
                                    rowCount={paymentData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {paymentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { admin_update_timestamp, receipt, username, checked, admin_id, created_date, is_self, amount, name, payment_id, bank, payment_status_id, status_string } = row;
                                        const isItemSelected = selected.indexOf(payment_id) !== -1;

                                        return (
                                            <TableRow
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                                hover
                                                key={payment_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >

                                                {process.env.REACT_APP_USER_TYPE == 'admin' && (

                                                    <TableCell>
                                                        {payment_status_id == 2 && (
                                                            <>
                                                                {admin_id == null ? (
                                                                    <Checkbox
                                                                        onChange={(e) => {
                                                                            handleCheck(e, row);
                                                                        }}
                                                                        checked={checked}
                                                                    ></Checkbox>
                                                                ) : (
                                                                    <>
                                                                        {is_self ? (
                                                                            <Chip variant="filled" color="primary" onDelete={() => {
                                                                                handleUnassign(payment_id);
                                                                            }} label={username} />
                                                                        ) :
                                                                            <Chip variant="filled" onDelete={() => {

                                                                            }} label={username} />
                                                                        }

                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                    </TableCell>
                                                )}


                                                <TableCell align="left">{created_date}</TableCell>
                                                <TableCell align="left">{payment_id}</TableCell>
                                                {
                                                    props.data.fields.map(f => (
                                                        <>
                                                            {f.type == 'file' && row[f.name] != null ? (
                                                                <TableCell align="left">
                                                                    <IconButton onClick={() => {
                                                                        handleOpenImage(row[f.name])
                                                                    }}>
                                                                        <ImageIcon></ImageIcon>
                                                                    </IconButton>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell align="left">{row[f.name]}</TableCell>
                                                            )}

                                                        </>
                                                    ))
                                                }
                                                {/* <TableCell align="left">{name}</TableCell> */}
                                                <TableCell align="right">{amount}</TableCell>
                                                <TableCell align="left">{bank}</TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={status_colors[payment_status_id]}
                                                    >
                                                        {translate(status_string)}
                                                        {/* {sentenceCase(status)} */}
                                                    </Label>
                                                </TableCell>
                                                <TableCell style={{
                                                    display: 'flex'
                                                }}>

                                                    <IconButton>
                                                        <RemoveRedEyeIcon
                                                            onClick={() => {
                                                                openPaymentDetails(payment_id);
                                                            }
                                                            }
                                                        />
                                                    </IconButton>
                                                    {
                                                        payment_status_id <= 2 && is_self == true && (
                                                            <>
                                                                <IconButton onClick={() => {
                                                                    update_status(payment_id, 3)
                                                                }}>
                                                                    <CheckIcon color="primary" />
                                                                </IconButton>
                                                                <IconButton onClick={() => {
                                                                    setOpenModal(true);
                                                                    setFocusedPayment(payment_id);
                                                                }}>
                                                                    <DoDisturbIcon color="error" />
                                                                </IconButton>
                                                            </>
                                                        )
                                                    }

                                                    {payment_status_id > 2 && (
                                                        <>
                                                            {user.role_id == 1 && (
                                                                <IconButton onClick={() => {
                                                                    handleEditPayment(row)
                                                                }}>
                                                                    <EditIcon></EditIcon>
                                                                </IconButton>
                                                            )}

                                                            <br />
                                                            <Typography variant="subtitle2" style={{
                                                                fontSize: "0.7em"
                                                            }}>
                                                                {translate("Updated By")}: {username}<br />
                                                                {admin_update_timestamp}
                                                            </Typography>
                                                        </>
                                                    )}


                                                </TableCell>


                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={paymentData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, page) => setPage(page)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal_content">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {translate("Reason for rejection")}
                    </Typography>
                    <br />
                    <TextField
                        onChange={handleRejectionFormChange}
                        select className="full_width" name="reason" value={rejectionForm.reason}>
                        {rejectionReasons.map((row) => (
                            <MenuItem value={row.payment_rejection_reason_id}>{translate(row.reason)}</MenuItem>
                        ))}
                    </TextField>
                    <br />
                    <br />
                    <TextField

                        onChange={handleRejectionFormChange}
                        lines={3} className="full_width" name="remarks" value={rejectionForm.remarks} placeholder={translate('remarks ( internal use only )')}></TextField>

                    <br />
                    <br />
                    <div style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>
                        <Button
                            onClick={() => {
                                update_status(focusedPayment, 4);
                            }}
                            color="primary" variant="contained">{translate("Submit")}</Button>
                    </div>
                </div>
            </Modal>

            <Modal open={openImage}
                onClose={handleCloseImage}
            >
                <div className="modal_content">
                    {focusedImage != '' && (
                        <img
                            style={{
                                width: "100%",
                                maxWidth: 700,
                                maxHeight: 500
                            }}

                            src={focusedImage} />

                    )}
                </div>
            </Modal>
            <Modal
                open={false}
                onClose={() => { }}
            >
                <div className="modal_content">
                    <Typography variant="subtitle2">Advanced Search</Typography>
                    <br />
                    <Typography variant="subtitle2" style={{
                        marginBottom: 10
                    }}>Date</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField label="From" name="date_from"
                                className="full_width"
                                type='date'
                                value={advancedSearch.date_from}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="To" name="date_from"
                                className="full_width"
                                type="date"
                                value={advancedSearch.date_from}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2"
                        style={{
                            marginBottom: 10
                        }}
                    >Time</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField label="From" name="time_from"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="time"
                                value={advancedSearch.time_from}
                                className="full_width"></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="To" name="time_from"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="time"
                                value={advancedSearch.time_from}
                                className="full_width"></TextField>
                        </Grid>
                    </Grid>


                </div>
            </Modal>

            {focusedEditPayemnt != null && (
                <Modal
                    open={openEditPaymentModal}
                    onClose={() => { setOpenEditPaymentModal(false) }}
                >
                    <div className="modal_content">
                        <Typography>Edit Payment</Typography>
                        <Table>
                            <TableRow>
                                <TableCell>Payment Date</TableCell>
                                <TableCell>{focusedEditPayemnt.created_date}</TableCell>
                            </TableRow>
                            {props.data.fields.map(f => (
                                <TableRow>
                                    <TableCell>{f.name}</TableCell>
                                    <TableCell>{focusedEditPayemnt[f.name]}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    Amount
                                </TableCell>
                                <TableCell>
                                    {focusedEditPayemnt.amount}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Approved By
                                </TableCell>
                                <TableCell>
                                    {focusedEditPayemnt.username}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Approved On
                                </TableCell>
                                <TableCell>
                                    {focusedEditPayemnt.admin_update_timestamp}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colspan={2} >
                                    <TextField
                                        value={amendPaymentReason}
                                        onChange={(e) => {
                                            setAmendPaymentReason(e.target.value);
                                        }}
                                        className="full_width"
                                        placeholder='Provide a reason for change'
                                    ></TextField>
                                </TableCell>
                            </TableRow>
                        </Table>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }}>
                            {focusedEditPayemnt.payment_status_id == 4 && (
                                <Button
                                    onClick={() => { handleAmendPayment(3) }}
                                    variant="contained" style={{
                                        marginRight: 20
                                    }}>Approve</Button>
                            )}
                            {
                                focusedEditPayemnt.payment_status_id == 3 && (
                                    <Button variant="contained" color="error"
                                        onClick={() => {
                                            handleAmendPayment(4)
                                        }}
                                    >Reject</Button>
                                )}

                        </div>
                    </div>
                </Modal>
            )}

        </>
    )
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_user) => _user.payment_id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default PaymentTable;